// 用户端-报告任务列表-接口
import {
	axios
} from '../utils/http.js'
const UserTaskApi = {
	UserTaskList: `/UserTask/List`,//报告任务列表
	TaskDataList: `/UserTaskData/List`,//验证报告数据
	TaskPDF: `/TaskData/Export_Data_PDF`,//验证报告数据
}

//设备分类列表
export function UserTaskList(data) {
	return axios({
		url: UserTaskApi.UserTaskList,
		method: 'post',
		data:data
	})
}

//设备分类列表
export function TaskDataList(data) {
	return axios({
		url: UserTaskApi.TaskDataList,
		method: 'post',
		data:data
	})
}
//设备分类列表
export function downloadPDF(data) {
	return axios({
		url: UserTaskApi.TaskPDF,
		method: 'post',
		data:data
	})
}