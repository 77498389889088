<template>
	<!--报告任务列表-->
	<div class="ReportTask">
		<div class="ReportTask1">
			<div class="ReportTask1_Nav">
				<div class="Task_search">
					<el-input type="text" prefix-icon="el-icon-search" placeholder="请输入内容" v-model="USERiNFO.T_name"
						size="mini" clearable @keyup.enter.native="searchValue" @clear="searchNav">
					</el-input>
				</div>
				<div class="Task_Conters">
					<div v-if="NavList!=null">
						<div :class="Navnum===index?'Task_Conters2':'Task_Conters1'" v-for="(item,index) in NavList"
							:key="index" @click="btnNav(item,index)">
							<span class="bianhao">
								<img src="../../assets/img/wenshidu2.png" alt="" style="width: 100%;height: 100%;"
									srcset="">
							</span>
							<p class="Task_Conters1_p">{{item.T_name}}</p>
							<i class="el-icon-arrow-right" style="color: #ccc;"></i>
						</div>
					</div>
					<div v-else>
						<el-empty description="暂无数据"></el-empty>
					</div>
				</div>
				<div class="Task_page">
					<div class="Task_page_2">
						<div class="Task_page_LNR Task_page_L" @click="TopYe">
							上一页
						</div>
						<div class="Task_page_LNR Task_page_Num">
							<input type="text" onkeyup="this.value=this.value.replace(/\D|^0/g,'')"
								v-model="USERiNFO.page" @keyup.enter="searchYe">
						</div>
						<div class="Task_page_LNR Task_page_R" @click="BottomYe">
							下一页
						</div>
					</div>
				</div>
			</div>
			<div style="width:15px;background: #f2f2f2;"></div>
			<div class="ReportTask1_Conters">
				<div class="ReportTask1_Conters1">
					<div>
						<el-button type="primary" icon="el-icon-view" @click="FacilityData(0)">验证设备数据
						</el-button>
						<el-button type="primary" icon="el-icon-view" @click="FacilityBook(1)">验证设备证书
						</el-button>
					</div>
					<div>
						<el-button type="primary" :disabled="pdf3Url===''?true:false" icon="el-icon-download"
							v-show="Facility===1" @click="downloadPDFs(1)">下载PDF
						</el-button>
						<el-button type="primary" :disabled="docxShow" icon="el-icon-download"
							v-show="Facility===0" @click="downloadPDFs(2)">下载数据
						</el-button>
					</div>
				</div>
				<div class="ReportTask1_Conters2">
					<FacilityData v-if="Facility===0 && FacilityDatas" :FacilityData="FacilityDatas" ref="getSelectData" @changecollapse="handlecollapse($event)"></FacilityData>
					<FacilityBook v-else-if="Facility===1 && FacilityDatas" :FacilityData="FacilityDatas">
					</FacilityBook>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		UserTaskList,
		downloadPDF
	} from './../../api/CompanyAccount.js'
	import FacilityData from "./modules/FacilityData.vue"
	import FacilityBook from "./modules/FacilityBook.vue"
	import axios from 'axios'
	let moment = require('moment');
	export default {
		components: {
			FacilityData,
			FacilityBook
		},
		data() {
			return {
				Navnum: 0,
				USERiNFO: {
					T_name: '',
					page: 1,
					page_z: 10,
				},
				pdf3Url: '', //证书的下载
				docxShow:null,
				Facility: 0,
				total: false,
				NavList: [],
				FacilityDatas: null
			}
		},
		created() {
			this.GetUserTaskListApi()
			this.handlecollapse()
		},
		methods: {
			searchNav(){
				this.USERiNFO.page = 1
				this.GetUserTaskListApi()
			},
			handlecollapse(change) {
				this.docxShow = change
			},
			downloadPDFs(i) {
				var Paraer = {
					T_task_id: this.FacilityDatas.T_task_id,
					Time_start: "",
					Time_end: ""
				}
				if (i === 2) { //下载数据
					Paraer.Time_start = this.$refs.getSelectData.Pamrefs.Time_start
					Paraer.Time_end = this.$refs.getSelectData.Pamrefs.Time_end
				}
				if (i === 1) { //下载证书pdf
					this.downPdf(this.pdf3Url)
				} else if (i === 2) {
					downloadPDF(Paraer).then(res => {
						if (res.data.Code === 200) {
							this.downPdf(res.data.Data)
						}
					})
				}
			},
			downPdf(PDFurl) {
				axios({
					url: PDFurl, // 服务器上.pdf路径
					method: 'get',
					responseType: 'blob',
				}).then(res => {
					const FileSaver = require('file-saver')
					const blob = new Blob([res.data], {
						type: 'application/pdf;charset=utf-8' // 此处type根据你想要的（此处下载pdf）
					})
					FileSaver.saveAs(blob, moment(new Date()).format('YYYYMMDDHHmmss')) // 下载的name文件名
				}).catch(err => {
					// eslint-disable-next-line no-undef
					console.log(err)
				})
			},
			TopYe() { //导航上一页
				if (this.USERiNFO.page === 1) {
					return
				} else {
					this.Navnum = 0
					this.USERiNFO.page = this.USERiNFO.page - 1
					this.GetUserTaskListApi()
				}
			},
			BottomYe() { //导航下一页
				if (this.USERiNFO.page >= this.total) {
					// this.Navnum = 0
					// this.USERiNFO.page = this.total
					// this.GetUserTaskListApi()
					return
				} else {
					this.Navnum = 0
					this.USERiNFO.page = this.USERiNFO.page + 1
					this.GetUserTaskListApi()
				}
			},
			btnNav(item, index) {
				this.FacilityDatas = item
				this.$nextTick(() => {
					this.pdf3Url = item.T_pdf3
				})
				this.Navnum = index
			},
			FacilityBook(e) {
				this.Facility = e
			},
			FacilityData(e) {
				this.Facility = e
			},
			GetUserTaskListApi() {
				var _this = this
				UserTaskList(this.USERiNFO).then(res => {
					if (res.data.Code === 200) {
						_this.NavList = res.data.Data.List
						_this.total = Math.ceil(res.data.Data.Num / this.USERiNFO.page_z) //页数
						if (this.NavList != null) {
							_this.FacilityDatas = this.NavList[0]
							_this.pdf3Url = this.NavList[0].T_pdf3
						}
					}
				})
			},
			searchYe() {
				if (0 < this.USERiNFO.page && this.total >= this.USERiNFO.page) {
					this.GetUserTaskListApi()
				} else {
					this.USERiNFO.page = 1
					this.GetUserTaskListApi()
				}
			},
			searchValue() { //搜索
				this.USERiNFO.page = 1
				this.GetUserTaskListApi()
			}
		}
	}
</script>

<style>
	.ReportTask {
		border-radius: 5px;
		height: 100%;
	}

	.ReportTask1 {
		height: 100%;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
	}

	.ReportTask1_Nav {
		max-width: 220px;
		display: flex;
		flex-direction: column;
	}

	.Task_search {
		padding: 10px;
		display: flex;
		align-items: center;
		box-shadow: 0 1px 2px 0 #ccc;
	}

	.Task_Conters {
		flex: 1;
		overflow-y: scroll;
	}

	.Task_Conters1_p {
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin: 0 10px;
	}

	.Task_Conters1 {
		padding: 15px 10px;
		cursor: pointer;
		font-size: 12px;
		text-overflow: #666;
		display: flex;
		align-items: center;
		color: #909399;
		border-radius: 2px;
	}

	.Task_Conters2 {
		padding: 15px 10px;
		cursor: pointer;
		font-size: 12px;
		text-overflow: #666;
		display: flex;
		align-items: center;
		background: #f2f2f2;
		color: #000;
		border-radius: 2px;
	}

	.bianhao {
		width: 20px;
		height: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid rgba(255, 119, 68, .5);
		border-radius: 2px;
	}

	.Task_Conters1:hover {
		background: rgba(64, 158, 255, .05);
		/* color: #fff; */
	}

	.Task_page {
		padding: 10px;
		text-align: center;
		display: flex;
		align-items: center;
		border-top: 1px solid #DCDFE6;
	}

	.Task_page_2 {
		display: flex;
		align-items: center;
		flex: 1;
		height: 30px;
	}

	.Task_page_LNR {
		background: #409EFF;
		height: 25px;
		color: #fff;
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 3px;
	}

	.Task_page_L {
		min-width: 55px;
		font-weight: bold;
		cursor: pointer;
	}

	.Task_page_L:hover {
		background: rgba(102, 177, 255, 1);
	}

	.Task_page_Num {
		flex: 1;
		margin: 0 10px;
	}

	.Task_page_Num>input {
		width: 100%;
		height: 100%;
		outline: none;
		border: none;
		background: none;
		text-align: center;
		color: #fff;
	}

	.Task_page_R {
		min-width: 55px;
		font-weight: bold;
		cursor: pointer;
	}

	.Task_page_R:hover {
		background: rgba(102, 177, 255, 1);
	}

	.ReportTask1_Conters {
		flex: 1;
		padding: 10px;
		overflow-y: scroll;
	}

	.ReportTask1_Conters1 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 15px;
		margin-bottom: 15px;
		border-bottom: 1px solid #DCDFE6;
	}
</style>
