<template>
	<!--设备证书-->
	<div class="FacilityBook">
		<div class="FacilityBook_1" v-if="pdfSrc===''">
			<el-empty :image-size="100" description="暂无可预览PDF数据显示"></el-empty>
		</div>
		<div class="FacilityBook_2" v-else>
			<div style="margin: 20px 0;font-weight: bold;font-size: 18px;">
				验证设备证书：共{{numPages}}页
			</div>
			<div :class="numPages!=1?'FacilityBook_2_1':'FacilityBook_2_2'">
				<pdf v-for="item in numPages" :key="item" :src="pdfSrc" :page="item" ref="pdf"></pdf>
			</div>
		</div>
	</div>
</template>

<script>
	import pdf from 'vue-pdf'
	export default {
		props:['FacilityData'],
		components: {
			pdf,
		},
		data() {
			return {
				pdfSrc: "", //
				numPages: null, // pdf 总页数
			}
		},
		watch: {
			FacilityData: {
				handler() {
					this.pdfSrc = this.FacilityData.T_pdf3
					this.$nextTick(() => {
						this.getNumPages()
					})
				},
				deep: true,
				immediate: true,
			},
		},
		mounted() {
			this.pdfSrc = this.FacilityData.T_pdf3
			console.log('整数',this.pdfSrc)
			this.getNumPages()
		},
		methods: {
			getNumPages() {
				if (this.pdfSrc) { //不为空
					let loadingTask = pdf.createLoadingTask(this.pdfSrc)
					loadingTask.promise.then(pdf => {
						this.numPages = pdf.numPages
					}).catch(err => {
						console.error('pdf 加载失败', err);
					})
				} else { //为空
					return
				}
			},
		}
	}
</script>

<style>
	.FacilityBook_2_1{
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;
	}
	.FacilityBook_2_1>span {
		border: 1px solid #DCDFE6;
	}
	
	.FacilityBook_2_2{
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 10px;
	}
	.FacilityBook_2_2>span {
		border: 1px solid #DCDFE6;
	}
	
	
	.FacilityBook_1 {
		padding: 50px 0;
		border: 1px solid #DCDFE6;
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
</style>
