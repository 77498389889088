<template>
	<!--设备数据-->
	<div class="FacilityData">
		<div class="FacilityData1">
			<div style="margin-right: 10px;">
				<el-input type="text" prefix-icon="el-icon-search" placeholder="请输入SN搜索" v-model="Pamrefs.T_sn"
					clearable @keyup.enter.native="searchValue" @clear="searchValue"></el-input>
			</div>
			<div style="margin-right: 10px;">
				<el-input type="text" prefix-icon="el-icon-search" placeholder="请输入设备编号搜索" v-model="Pamrefs.T_id"
					clearable @keyup.enter.native="searchValue" @clear="searchValue"></el-input>
			</div>
			<div style="margin-right: 10px;">
				<el-date-picker v-model="Times" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="searchTimeData">
				</el-date-picker>
			</div>
			<el-button type="primary" icon="el-icon-search" @click="searchValue()">搜索
			</el-button>
		</div>
		<div class="FacilityData2">
			<el-table :data="tableData" style="width: 100%" border>
				<el-table-column prop="T_id" label="编号" width="180">
				</el-table-column>
				<el-table-column prop="T_sn" label="SN">
				</el-table-column>
				<el-table-column prop="T_t" label="温度(℃)">
				</el-table-column>
				<el-table-column prop="T_rh" label="湿度(%)" width="180">
				</el-table-column>
				<el-table-column prop="T_time" label="时间" width="180">
				</el-table-column>
			</el-table>
		</div>
		<div class="FacilityData3">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="Pamrefs.page" :page-sizes="[10, 20, 50, 100]" :page-size="Pamrefs.page_z"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		TaskDataList
	} from '../../../api/CompanyAccount.js'
	export default {
		props: ['FacilityData'],
		data() {
			return {
				Times: '',
				FormData: {
					T_sn: '',
					T_id: ''
				},
				tableData: [],
				Pamrefs: {
					T_task_id: '',
					T_sn: '',
					T_id: '',
					Time_start: '',
					Time_end: '',
					page: 1, //当前页
					page_z: 10, //每页显示多少条
				},
				total: 0,
			}
		},
		watch: {
			FacilityData: {
				handler() {
					this.Pamrefs.T_task_id = this.FacilityData.T_task_id
					this.Pamrefs.page = 1
					this.$nextTick(() => {
						this.PostTaskDataListApi()
					})
				},
				deep: true,
				immediate: true,
			},
		},
		mounted() {
			console.log('mounted钩子中接管', this.FacilityData);
			this.Pamrefs.T_task_id = this.FacilityData.T_task_id
			this.$nextTick(() => {
				this.PostTaskDataListApi()
			})
		},
		methods: {
			PostTaskDataListApi() {
				console.log('请求', this.Pamrefs);
				var _this = this
				TaskDataList(this.Pamrefs).then(res => {
					console.log('验证报告数据', res)
					if (res.data.Code === 200) {
						_this.total = res.data.Data.Num
						var Arrs = res.data.Data.List
						Arrs.forEach(function(obj) {
							obj.T_id = obj.T_id.toString().padStart(3,'0')
							console.log(obj.T_id)
						})
						_this.tableData = Arrs
						console.log('_this.tableData',this.$parent.Navnum)
						if(this.tableData===null){
							this.$emit("changecollapse",true)
						}else{
							this.$emit("changecollapse",false)
						}
					}
				})
			},
			searchValue() {
				this.Pamrefs.page = 1
				this.PostTaskDataListApi()
			},
			searchTimeData(e) {
				this.Pamrefs.page = 1
				if (e != null) {
					this.Pamrefs.Time_start = e[0]
					this.Pamrefs.Time_end = e[1]
					this.PostTaskDataListApi()
				} else {
					this.Pamrefs.Time_start = ""
					this.Pamrefs.Time_end = ""
					this.PostTaskDataListApi()
				}
			},
			handleSizeChange(val) { //每页多少条条
				console.log(`每页 ${val} 条`);
				this.Pamrefs.page_z = val
				this.PostTaskDataListApi()
			},
			handleCurrentChange(val) { //当前页
				console.log(`当前页: ${val}`);
				this.Pamrefs.page = val
				this.PostTaskDataListApi()
			}
		}
	}
</script>

<style>
	.FacilityData1 {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}

	.FacilityData3 {
		margin-top: 10px;
	}
</style>
